.card-container {
    width: 350px;
    height: 190px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
}

.Dark .card-container {
    border: 1px solid white;
    transition: 2s;
    color: black;
}

.Light .card-container {
    transition: 2s;
}

.card-container:hover {
    -webkit-box-shadow: 0px 0px 20px -5px rgba(#0084E3);
    box-shadow: 0px 0px 20px -5px rgba(#0084E3);
}

.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    /* border: 1px solid blue; */
}

.img-container img {
    width: 100%;
    transition: 1s;
    object-fit: cover;
    border-radius: 30px;
}

.img-container img:hover {
    filter: invert(180%);
}

.description-container {
    width: 95%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    /* border: 1px solid blueviolet; */
}

.description-container p {
    font-size: 14px;
    text-align: left;
    margin: 0;
}


.btn-webs {
    border: 1px solid #0084E3;
    background-color: white;
    width: 100px;
    height: 28px;
    font-size: 13px;
    color: black;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    letter-spacing: 3px;
    border-radius: 20px;
    transition: 0.5s;

}

.Dark .btn-webs {
    background-color: rgb(44, 42, 42);
    color: #0084E3;
    transition: 2s;
}

.Light .btn-webs {
    transition: 2s;
}

.btn-webs:hover {
    background-color: #0084E3;
    color: white;
}

.btn-container {
    display: flex;
    justify-content: end;
}

/** MEDIA QUERIES TO TABLET**/

@media (min-width: 768px) and (max-width: 1279px) {
    .card-container {
        width: 48%;
        margin: auto;
    }

    .img-container img {
        height: 130px;
    }

    .description-container p {
        font-size: 15px;
    }
}

/** MEDIA QUERIES TO DESKTOP **/

@media (min-width: 1280px) {
    .card-container {
        width: 40%;
        height: 200px;
        /* border: 1px solid black; */
        /* margin: auto; */
        /* margin-bottom: 10px; */
        /* margin-top: 45px; */
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        /* border: 1px solid blue; */
    }

    .img-container img {
        width: 170px;
        height: 180px;
        padding: 5px;
    }

    .description-container {
        width: 60%;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        /* border: 1px solid blueviolet; */
    }

    .description-container p {
        font-size: 16px;
        text-align: justify;
        margin: 0;
    }
}