* {
    margin: 0;
    padding: 0;
}

.container-header {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-bottom: 20px;
}

.my-name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 65px;
    font-weight: 800;
    letter-spacing: 15px;
    text-align: center;
}

.last-names {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 23px;
    margin-top: 10px;
    letter-spacing: 9px;
    margin-bottom: 70px;
}

.fx {
    display: block;
    white-space: nowrap;
    border-right: 2px solid;
    width: 19ch;
    height: 30px;
    animation: text 4s infinite steps(19),
        blink .5s infinite step-end alternate;
    overflow: hidden;
}

@keyframes text {
    from {
        width: 0
    }
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}

@media (min-width: 1280px) {
    .fx {
        height: 33px;
    }
}