.footer-container {
  /* height: 100vh; */
  width: 100vw;
  /* margin-top: 50px;
    margin-bottom: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
}

.footer-title {
  font-size: 30px;
  margin-bottom: 50px;
}

.icons-container {
  height: 70px;
  width: 100vw;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.icon-container {
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: black;
  transition: 1s;
}

.Dark .icon-container {
  color: #0084e3;
}

.icon-container:hover {
  color: #0084e3;
  transform: scale(1.5, 1.5);
}

.Dark .icon-container:hover {
  color: white;
}

/** MEDIA QUERIES TO DESKTOP **/

@media (min-width: 1280px) {
  .footer-container {
    width: 50%;
    margin: auto;
  }

  .footer-title {
    font-size: 50px;
  }

  .icons-container {
    width: 50vw;
  }
}
