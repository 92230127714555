.sec-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* justify-content: space-between; */
    /* border: 1px black solid; */
    height: 100vh;
    width: 100%;
    margin: 0;
    margin-bottom: 300px;
}

.sec-container h2 {
    font-size: 30px;
    margin-bottom: 900px;
    padding: 10px;
    text-align: left;
    filter: drop-shadow(-40px 0px 30px rgba(0, 0, 0, 0.5));
}

.sec-container div {
    width: 100%;
    height: 300px;
    padding: 10px;
    /* border: 1px solid black; */
}

.sec-container p {
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    text-align: justify;
}

li{
    text-align: left;
}

h5{
    text-align: left;
}


/** MEDIA QUERIES TO TABLET**/

@media (min-width: 768px) and (max-width: 1279px) {
    .sec-container {
        width: 85%;
        margin: auto;
    }

    .sec-container h2 {
        font-size: 50px;
    }

    .sec-container p {
        font-size: 19px;
    }
}


/** MEDIA QUARIES FOR TABLETS AND DESKTOP **/

@media (min-width: 1279px) {
    .sec-container {
        display: flex;
        justify-content: center;
        justify-content: space-around;
        /* align-items: center; */
        /* justify-content: space-between; */
        /* border: 1px black solid; */
        height: 100vh;
        width: 80%;
        margin: auto;
    }

    .sec-container h2 {
        font-size: 55px;
        margin-bottom: 900px;
        padding: 10px;
        text-align: left;
        filter: drop-shadow(-40px 0px 30px rgba(0, 0, 0, 0.5));
    }

    .sec-container div {
        width: 50%;
        height: 300px;
        padding: 10px;
        /* border: 1px solid black; */
    }

    .sec-container p {
        display: flex;
        justify-content: flex-start;
        font-size: 17px;
        text-align: justify;
    }

    .sec-container h5 {
        text-align: left;
    }
}