.App {
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: white;
}

.Dark.App {
  background-color: #212529;
  color: white;
  transition: 2s;
}

.Light.App {
  transition: 2s;
  background-color: #F4F5FA;
}