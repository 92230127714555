.container-main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: auto;
    gap: 15px;
}

.title-elements {
    font-size: 40px;
    margin-bottom: 20px;
}

.title-elements::after {
    content: ' />';
    color: #0084E3;
}

.title-elements::before {
    content: '<';
    color: #0084E3;
}

@media (min-width: 768px) and (max-width: 1279px) {
    .container-main {
        width: 95%;
    }

    .title-elements {
        font-size: 50px;
    }
}

/** MEDIA QUERIES TO DESKTOP **/

@media (min-width: 1280px) {
    .title-elements {
        font-size: 55px;
        margin-bottom: 100px;
    }

    .container-main {
        width: 80%;
        min-height: 100vh;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: auto;
    }
}