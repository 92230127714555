.skills-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
}

.skills-container h1 {
    margin-bottom: 50px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.skills-logo-container {
    width: 80%;
    margin: 0 auto;
    height: 300px;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.skills-icon-container {
    width: 30px;
    height: 30px;
    color: black;
    transition: 1s;
    margin: 20px;
}

.skills-icon-container:hover {
    color: #0084E3;
}

.Dark .skills-icon-container:hover {
    color: white;
}

.Dark .skills-icon-container {
    color: #0084E3;
}


/** MEDIA QUERIES FOR TABLETS  **/
@media (min-width: 768px) {
    .skills-logo-container {
        width: 70%;
        margin: 0 auto;
        height: 300px;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .skills-icon-container {
        width: 40px;
        height: 40px;
        margin: 40px;
    }
}

/** MEDIA QUERIES TO DESKTOP  **/

@media (min-width: 1280px) {
    .skills-logo-container {
        width: 40%;
        margin: 0 auto;
        height: 300px;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .skills-icon-container {
        margin: 50px;
    }
}

@media (min-width: 1640px) {
    .skills-icon-container {
        margin: 70px;
    }
}