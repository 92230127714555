form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: solid 1px #fff;
  width: 90%;
  height: 550px;
  margin: auto;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

input {
  margin: 10px 10px;
  padding: 10px;
  font-size: 16px;
  /* border: none; */
  /* border-bottom: 1px solid #fc9157; */
  border-radius: 20px;
  width: 100%;
}

.textarea-msg {
  margin: 10px;
  padding: 5px;
  font-size: 16px;
  /* border: none; */
  /* border-bottom: 1px solid #ccc; */
  height: 130px;
  resize: none;
  border-radius: 20px;
}
.textarea-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 170px;
}

.btn-form {
  background-color: #0084e3;
  color: white;
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  border: none;
  width: 150px;
  border-radius: 20px;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 14px;
  padding-left: 10px;
  width: 80%;
}

.inputs-containers {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100px;
}

.success-msg {
  color: #03c988;
  font-size: 15px !important;
  padding: 0;
  margin: 0;
}

/*             TABLET MEDIA QUERIES                       */

@media (min-width: 768px) {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 600px;
    height: 550px;
    margin: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  }

  input {
    margin: 10px 10px;
    padding: 10px;
    font-size: 16px;
    /* border: none; */
    /* border-bottom: 1px solid #fc9157; */
    border-radius: 20px;
    width: 100%;
  }

  .textarea-msg {
    margin: 10px;
    padding: 5px;
    font-size: 16px;
    /* border: none; */
    /* border-bottom: 1px solid #ccc; */
    height: 130px;
    resize: none;
    border-radius: 20px;
  }
  .textarea-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 170px;
  }

  .btn-form {
    background-color: #0084e3;
    color: white;
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    border: none;
    width: 150px;
    border-radius: 20px;
    cursor: pointer;
  }

  .error {
    color: red;
    font-size: 14px;
    padding-left: 10px;
    width: 80%;
  }

  .inputs-containers {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100px;
  }

  .success-msg {
    color: #03c988;
    font-size: 15px !important;
    padding: 0;
    margin: 0;
  }
}

/*          PC MEDIA QUERIES         */

@media (min-width: 1280px) {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 500px;
    height: 550px;
    margin: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  }

  input {
    margin: 10px 10px;
    padding: 10px;
    font-size: 16px;
    /* border: none; */
    /* border-bottom: 1px solid #fc9157; */
    border-radius: 20px;
    width: 100%;
  }

  .textarea-msg {
    margin: 10px;
    padding: 5px;
    font-size: 16px;
    /* border: none; */
    /* border-bottom: 1px solid #ccc; */
    height: 130px;
    resize: none;
    border-radius: 20px;
  }
  .textarea-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 170px;
  }

  .btn-form {
    background-color: #0084e3;
    color: white;
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    border: none;
    width: 150px;
    border-radius: 20px;
    cursor: pointer;
  }

  .error {
    color: red;
    font-size: 14px;
    padding-left: 10px;
    width: 80%;
  }

  .inputs-containers {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100px;
  }

  .success-msg {
    color: #03c988;
    font-size: 15px !important;
    padding: 0;
    margin: 0;
  }
}
